
@import url('https://fonts.googleapis.com/css?family=Poppins');

// Typography
$font-family-sans-serif: 'Poppins';
$font-size-base: 0.9rem;
$line-height-base: 1.6;

$primary: #38658a;
$danger: #C60C30;
$body-bg: rgb(245, 245, 245);